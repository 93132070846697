<script setup>
import {onActivated, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {MemberStore} from "@/uhas/store/member";

const router = useRouter();
const route = useRoute();
const memberStore = MemberStore();

const session = ref(null);

/**
 * Data
 */
const loading = ref(true);

/**
 * Computed
 */

/**
 * Mounted
 */
onMounted (() => {
	getCourseData();
});

/**
 * Activated
 */
onActivated (() => {
	if (!session.value) {
		return
	}

	if (session.value.course_id.toString() !== route.params?.id.toString() || session.value.id.toString() !== route.params?.ep.toString()) {
		getCourseData();
	}
})


/**
 * Actions
 */
const getCourseData = () => {
	memberStore.getCourse(route.params?.id)
		.then(course => {
			if (course && course.courseSessions) {
				session.value = course.courseSessions.find(item => item.id.toString() === route.params?.ep);
			}
		})
		.finally(() => {
			loading.value = false;
		});
}
</script>

<style lang="scss" scoped>
::v-deep {
	img {
		object-fit: cover;
	}
	.p-card-body, .p-card-content {
		padding: 0;
	}

	.right-detail {
		.p-card-title, .p-card-subtitle, .p-card-subtitle + div {
			max-height: 100px;
			overflow-y: hidden;
		}
	}

	.hilight-img {
		position: absolute;
    left: -28px;
    right: -28px;
    top: -28px;
		z-index: -1;
	}
	.back-btn {
		position: absolute;
		top: 0;
	}
}
::v-deep .p-component-overlay {
	animation: unset !important;
	background-color: rgba(255, 255, 255, 0.4);
}
</style>

<template>
	<div
		v-if="session"
		class="relative"
		:style="session.picture_url_main ? 'padding-top: 200px;' : ''"
	>
		<Image
			v-if="session.picture_url_main"
			:src="session.picture_url_main"
			class="hilight-img"
			imageClass="w-full"
			imageStyle="max-height: 300px;"
		/>

		<Button
			label="กลับ"
			icon="pi pi-angle-double-left"
			class="back-btn p-button-lg p-button-outlined p-button-raised text-white"
			@click="router.push({name: 'course-sessions', params: {id: route.params?.id}})"
		/>

		<Card
			v-if="session.title_detail || session.title || session.editor_description_detail || session.editor_description"
			class="w-full md:w-11 lg:w-10 xl:w-9 mx-auto p-4 mb-6"
		>
			<template #subtitle>
				{{session.sub_title}}
			</template>
			<template #title>
				{{session.title}}
			</template>
			<template #content>
				<div v-html="session.description"></div>

				<div v-html="session.editor_main" class="mt-4"></div>
			</template>
		</Card>
	</div>
	<ProgressBar
		mode="indeterminate"
		style="height: 0.3em"
		class="mt-4"
		:class="{'hidden': !loading}"
	/>
	<Card v-if="!loading && !session" class="mx-auto text-center p-4">
		<template #content>
			<div class="font-bold text-2xl text-gray-600 mb-3">ไม่พบหน้าที่ต้องการ</div>
			<router-link :to="{name: 'courses'}">กลับไปหน้าคอร์สเรียน</router-link>
		</template>
	</Card>
</template>
